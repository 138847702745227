import { SVGProps } from 'react';

const MailFill = ({ fill, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="var(--neutral-500)"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_956_11913)">
      <path
        d="M21.9617 6.61841L14.9467 13.6334C14.1645 14.4136 13.1048 14.8518 12 14.8518C10.8952 14.8518 9.83552 14.4136 9.05333 13.6334L2.03833 6.61841C2.02667 6.75007 2 6.86924 2 7.00007V17.0001C2.00132 18.1047 2.44073 19.1638 3.22185 19.9449C4.00296 20.726 5.062 21.1654 6.16667 21.1667H17.8333C18.938 21.1654 19.997 20.726 20.7782 19.9449C21.5593 19.1638 21.9987 18.1047 22 17.0001V7.00007C22 6.86924 21.9733 6.75007 21.9617 6.61841Z"
        fill={fill}
      />
      <path
        d="M13.7684 12.4549L21.3801 4.84242C21.0114 4.23102 20.4913 3.72495 19.8701 3.37303C19.2489 3.0211 18.5474 2.8352 17.8335 2.83325H6.16678C5.4528 2.8352 4.75137 3.0211 4.13014 3.37303C3.50891 3.72495 2.98885 4.23102 2.62012 4.84242L10.2318 12.4549C10.7014 12.9227 11.3373 13.1853 12.0001 13.1853C12.663 13.1853 13.2988 12.9227 13.7684 12.4549Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_956_11913">
        <rect width={20} height={20} fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

export default MailFill;
